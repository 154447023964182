import React, { useMemo, useContext, useEffect } from 'react'
import { StateContext } from '../../services/StateService'
import { Translations } from '../../services/TranslationService'
import DataTable from '@3mcom/mds-library/dist/node/DataTable'
import { Link } from '@3mcom/mds-library/dist/node/index'
import '../../css/RelatedProducts.scss'
import { useIsDesktop } from '../../services/UtilityService'
import { getRequest } from '../../services/AxiosService'

const RelatedProducts = ({ relatedProductsRef }) => {
    const {
        moreOptions,
        productDetails,
        endpoints,
        relatedProducts,
        relatedProductsAttributes,
        classificationAttributes,
        dispatch
    } = useContext(StateContext)

    if (!relatedProducts) return null

    const isDesktop = useIsDesktop()
    const translations = useContext(Translations)
    const columns = useMemo(() => {
        const tempCols = [
            {
                id: 'column0',
                accessor: 'column0',
                Header: <div className="sps2-pdp_relatedProducts--header"></div>,
                style: {
                    width: isDesktop ? '20%' : '184px',
                    maxWidth: isDesktop ? '270px' : ''
                }
            }
        ]
        relatedProducts?.forEach((product, i) => {
            return tempCols.push({
                id: `column${i + 1}`,
                accessor: `column${i + 1}`,
                Header: () => getHeader(i),
                style: {
                    width: isDesktop ? '20%' : '184px',
                    maxWidth: isDesktop ? '270px' : ''
                }
            })
        })
        return tempCols
    }, [isDesktop, relatedProductsAttributes])

    useEffect(() => {
        getRelatedProductsCompare()
    }, [])

    const getRelatedProductsCompare = () => {
        const mmmIds = relatedProducts
            ?.filter((product, i) => i > 0)
            ?.map((prod) => prod.mmmid)

        if (Array.isArray(mmmIds)) {
            const endpoint = `${endpoints.relatedProductsCompare}?mmmIds=` + mmmIds

            getRequest(endpoint)
                .then((response) => {
                    if (response.data) {
                        dispatch({
                            type: 'pdp_related_products_compare',
                            payload: response.data
                        })
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: 'show_error_message',
                        payload: `Error on loading relatedProductsCompare: ${error}`
                    })
                })
        }
    }

    /**
     * Function that takes in a position in the array of relatedProducts in order to
     * display the header for the related products table.
     * @param {number} column The number that the loop for adding headers is returning
     * @returns A header cell with images and links pertaining to the product.
     */
    const getHeader = (column) => {
        const currentProduct = column === 0
        const product = relatedProducts[column]

        return (
            <div className="sps2-pdp_relatedProducts--table-header">
                {currentProduct && (
                    <div className="sps2-pdp_relatedProducts--table-header_currentProduct">
                        <p className="mds-font_legal">{translations.currentProduct}</p>
                    </div>
                )}

                <Link
                    href={currentProduct ? productDetails?.canonical : product?.url}
                    className="sps2-pdp_relatedProducts--table-header_link mds-link mds-link_secondary"
                >
                    <img
                        className="sps2-pdp_relatedProducts--table-header_img"
                        src={product?.image}
                        alt={product?.altText}
                        loading="lazy"
                    ></img>
                    {product?.name}
                </Link>
            </div>
        )
    }

    /**
     * Function to return the relatedProducts data formatted for the MDS Table
     * @returns An array of objects that contain data along with its respective
     * column it belongs in.
     */
    const getData = () => {
        const columnData = []
        let attributes
        // if the product has selectors the related products attributes should match with it
        // if not it will display the classificationAttributes
        if (moreOptions?.classificationAttributes?.length > 0) {
            attributes = moreOptions?.classificationAttributes.filter(
                (selectorAttribute) => {
                    return moreOptions.options.find(
                        (selectorOption) =>
                            selectorOption.identifier === selectorAttribute.identifier
                    )
                }
            )
        } else {
            attributes = classificationAttributes
        }

        attributes?.forEach((option, i) => {
            // Loop through the relatedProductsAttributes to find a matching attribute
            const attribute = relatedProductsAttributes?.find((attribute) => {
                if (attribute.identifier === option.identifier) return attribute
            })
            if (attribute) {
                // If there is a matching attribute to the selectors - add the info to the table
                const tempCols = {}

                // Populate the current products data first
                tempCols.column0 = option?.label
                tempCols.column1 = option?.values?.join(', ') || ''

                // Populate related products data buy going through the products array
                relatedProducts?.forEach((productComp, i) => {
                    // First item is the current product
                    if (i !== 0) {
                        const relatedData = []
                        // Make sure the product contains the attribute
                        const productMatch = attribute.products.find((product) => {
                            if (product?.id === productComp?.mmmid) return product
                        })

                        if (productMatch && productMatch?.value) {
                            // If it does then return its values
                            productMatch.value.forEach((value) => {
                                relatedData.push(
                                    `${value.value}${
                                        value?.uomId ? ' ' + value.uomId : ''
                                    }`
                                )
                            })
                            tempCols[`column${i + 1}`] = relatedData?.join(', ') || ''
                        } else {
                            // If it doesn't then return a blank value to avoid shifting the data over
                            relatedData.push('')
                            tempCols[`column${i + 1}`] = relatedData?.join(', ') || ''
                        }
                    }
                })

                columnData.push(tempCols)
            } else {
                // Attribute for the selector is not found in the related products
                const tempCols = {}

                // Populate current product data only and leave the other columns blank
                tempCols.column0 = option?.label
                tempCols.column1 = option?.values?.join(', ') || ''
                columnData.push(tempCols)
            }
        })

        return columnData
    }

    return (
        <div
            ref={relatedProductsRef}
            className="sps2-pdp_section sps2-pdp_relatedProducts"
        >
            <h3 className="mds-font_header--3" tabIndex="-1">
                {translations?.relatedProducts}
            </h3>
            <DataTable.Table
                containerStyle={{
                    width: isDesktop ? `${columns.length * 20}%` : '100%',
                    overflow: 'auto'
                }}
                columns={columns}
                data={getData()}
                percentageBase={!!isDesktop}
                disableHeader={false}
                sort={false}
                buttonText={translations.loadMore}
                pagIntermediateText={translations.of}
            ></DataTable.Table>
        </div>
    )
}

export default RelatedProducts
