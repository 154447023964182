import React, { useContext, useEffect, useState } from 'react'
import { StateContext } from '../../services/StateService'
import { getRequest } from '../../services/AxiosService'
import Spinner from '../../components/Spinner'
import WhereToBuy from './WhereToBuy'

const WhereToBuyController = ({ displaySelectors, getProductEndpoint }) => {
    const {
        ctaInfo,
        productDetails,
        isParent,
        replacement,
        moreOptions,
        selectedOptions,
        oneProductSelection,
        locale,
        dispatch
    } = useContext(StateContext)

    const [wtbProps, setWtbProps] = useState()
    const [widgetKey, setWidgetKey] = useState()

    const isParentWithVisibleSKUs =
        isParent === true && moreOptions?.displayTable === true

    const isParentWithHiddenSKUs =
        isParent === true &&
        moreOptions?.displayTable === false &&
        moreOptions?.options?.length > 0

    /**
     * isParent is false even if it is a parent to ensure children are hidden. In this case,
     * we don't display dropdowns (filters) and we try to load the widget with the hole set of wtbMmmIds.
     */
    const isParentWithHiddenSKUsAndFilters =
        (moreOptions?.options?.length === 0 || isParent === false) &&
        moreOptions?.displayTable === false &&
        moreOptions?.wtbMmmIds?.length > 0

    /**
     * Same as before (isParentWithHiddenSKUsAndFilters), but it doesn't have wtbMmmIds.
     * It behaves as a SKU. We need to use originalMmmid and stockNumber instead of wtbMmmIds.
     */
    const isParentWithOneHiddenSKU =
        isParent === false &&
        moreOptions?.displayTable === false &&
        !moreOptions?.wtbMmmIds

    const isSKU = isParent === false && moreOptions?.displayTable === true

    useEffect(() => {
        /**
         * If user narrows to a single product option, we should do an APi call to get the details of that product
         * in order to display the inline widget or the marketplace view buying options button for that specific product
         * or there are some parent products (branded sites mostly)
         * and they have WTB Info but they don't have any selector so if the total selected === 1
         * and there are no product options and there is WTB info we will try to load the WTB widget with
         * that information.
         */
        if (selectedOptions?.totalSelected === 1 && isParentWithVisibleSKUs) {
            dispatch({ type: 'pdp_set_loading_CCWTBScript', payload: true })
            const endpoint = getProductEndpoint(true)
            getRequest(endpoint)
                .then((response) => {
                    if (response?.data) {
                        if (Array.isArray(response.data.productOptions)) {
                            const singleProduct = response.data.productOptions[0]
                            const {
                                ctaInfo: singleProductCTAInfo,
                                productDetails: singleProductDetails
                            } = singleProduct
                            dispatch({
                                type: 'pdp_set_one_product_selection',
                                payload: {
                                    ...(singleProductCTAInfo || {}),
                                    ...(singleProductDetails || {})
                                }
                            })
                        } else {
                            if (ctaInfo) {
                                dispatch({
                                    type: 'pdp_set_one_product_selection',
                                    payload: {
                                        ...(ctaInfo || {}),
                                        ...(productDetails || {})
                                    }
                                })
                            }
                        }
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'pdp_set_loading_CCWTBScript', payload: false })
                    console.log(
                        'Purchase Options Error: Cannot get single child info ' + error
                    )
                })
        } else {
            if (oneProductSelection && Object.keys(oneProductSelection).length > 0) {
                dispatch({
                    type: 'pdp_set_one_product_selection',
                    payload: {}
                })
            }
        }
        /** If selectors are cleared, we reset */
        /* istanbul ignore next */
        if (
            selectedOptions?.totalSelected === moreOptions?.totalSelected &&
            wtbProps &&
            !isSKU &&
            !ctaInfo?.pdpSelectorOverride
        ) {
            setWtbProps(null)
            setWidgetKey(null)
        }
    }, [selectedOptions?.totalSelected])

    /**
     * If is a parent product with visible SKUs and user narrows down to a single selection, is not discontinued,
     * has CC (comerce conector) data and it's not a marketplace product or there is no marketplace data,
     * we will try to load the WTB widget for that product.
     */
    useEffect(() => {
        if (
            isParentWithVisibleSKUs &&
            selectedOptions?.totalSelected === 1 &&
            !replacement?.discontinued &&
            oneProductSelection?.ccSubId
        ) {
            setWtbProps({
                ccTemplatedID: oneProductSelection?.ccTemplatedID,
                ccSubId: oneProductSelection?.ccSubId,
                mmmId: oneProductSelection?.originalMmmid,
                locale: locale,
                widgetType: 'Parent with visible SKUs (narrows to 1)'
            })
        }
    }, [oneProductSelection])

    /**
     * If is a parent product with hidden SKUs, not discontinued and has CC (comerce conector) data, we will try to load
     * the WTB widget after users filter, allowing the subsets of products/SKUs (wtbMmmIds) to show links to distributors.
     */
    useEffect(() => {
        if (
            selectedOptions?.wtbMmmIds &&
            isParentWithHiddenSKUs &&
            !replacement?.discontinued &&
            ctaInfo?.ccSubId
        ) {
            setWtbProps({
                ccTemplatedID: ctaInfo?.ccTemplatedID,
                ccSubId: ctaInfo?.ccSubId,
                mmmId: selectedOptions?.wtbMmmIds?.join(','),
                locale: locale,
                widgetType: 'Parent with hidden SKUs'
            })
        }
    }, [selectedOptions?.wtbMmmIds])

    useEffect(() => {
        if (wtbProps && !isSKU) {
            dispatch({ type: 'pdp_set_loading_CCWTBScript', payload: true })
            setWidgetKey((prevWidgetKey) => (prevWidgetKey >= 1 ? prevWidgetKey + 1 : 1))
        }
    }, [wtbProps])

    useEffect(() => {
        /** Not discontinued, has CC (comerce conector) data */
        const shouldSetWtbProps = !replacement?.discontinued && ctaInfo?.ccSubId
        /**
         * If is a SKU/children and mini table is present or there are no selectors
         */
        if (
            isSKU &&
            shouldSetWtbProps &&
            (!moreOptions?.classificationAttributes?.length > 0 || !displaySelectors)
        ) {
            setWtbProps({
                ccTemplatedID: ctaInfo?.ccTemplatedID,
                ccSubId: ctaInfo?.ccSubId,
                mmmId: `${productDetails?.stockNumber},${productDetails?.originalMmmid}`,
                locale: locale,
                widgetType: 'SKU'
            })
            dispatch({ type: 'pdp_set_loading_CCWTBScript', payload: true })
            setWidgetKey(1)
        }

        /** If is a parent with hidden dropdowns but has children/SKUs */
        if (
            (isParentWithHiddenSKUsAndFilters ||
                (ctaInfo?.pdpSelectorOverride && moreOptions?.wtbMmmIds)) &&
            shouldSetWtbProps
        ) {
            setWtbProps({
                ccTemplatedID: ctaInfo?.ccTemplatedID,
                ccSubId: ctaInfo?.ccSubId,
                mmmId: moreOptions?.wtbMmmIds.join(','),
                locale: locale,
                widgetType: 'Parent with hidden SKUs and filters'
            })
        } else if (isParentWithOneHiddenSKU && shouldSetWtbProps) {
            setWtbProps({
                ccTemplatedID: ctaInfo?.ccTemplatedID,
                ccSubId: ctaInfo?.ccSubId,
                mmmId: `${productDetails?.stockNumber},${productDetails?.originalMmmid}`,
                locale: locale,
                widgetType: 'Parent with one hidden SKU'
            })
        }
    }, [])

    if (isSKU && displaySelectors) return null

    return (
        <>
            {wtbProps && widgetKey && (
                <>
                    {ctaInfo?.loadingCCWTBScript && (
                        <div className="sps2-pdp_purchase--CCloader">
                            <Spinner />
                        </div>
                    )}
                    <WhereToBuy
                        key={widgetKey}
                        ccTemplatedID={wtbProps?.ccTemplatedID}
                        ccSubId={wtbProps?.ccSubId}
                        mmmId={wtbProps?.mmmId}
                        locale={locale}
                        widgetType={wtbProps?.widgetType}
                    />
                </>
            )}
        </>
    )
}

export default WhereToBuyController
