import React, { useContext, useState, useEffect } from 'react'
import { StateContext } from '../../services/StateService'
import { Translations } from '../../services/TranslationService.jsx'
import { getLoadMoreURL, isImageURL } from '../../services/URLService.jsx'
import { NO_IMAGE, RESOURCE_TITLE_LENGTH } from '../../constants/constants.js'
import { getRequest } from '../../services/AxiosService.jsx'
import { ProductBar } from '@3mcom/mds-library/dist/node/index'
import {
    fancyText,
    formatMIME,
    formatBytes,
    removeEmphasis,
    useIsDesktop
} from '../../services/UtilityService.jsx'
import { trackPDPData } from '../../services/TealiumService'
import Tabs from '@3mcom/mds-library/dist/node/Tabs'
import Icons from '@3mcom/mds-library/dist/node/Icons'
import Alert from '@3mcom/mds-library/dist/node/Alert'
import '../../css/Resources.scss'
import { checkContentType, getIFUDocument } from '../../services/PDPService'

const Resources = ({ resourcesRef }) => {
    const [tabsData, setTabsData] = useState(null)
    const [activeTab, setActiveTab] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [IFUDocument, setIFUDocument] = useState('')
    const isDesktop = useIsDesktop()

    const translations = useContext(Translations)

    const { resources, showMore, techDataSheet, documentNumber, ajaxError } = translations

    const {
        resourcesMore,
        dispatch,
        resources: resourcesList,
        resourcesAggs,
        endpoints,
        resourcesType,
        instructions,
        locale,
        errors
    } = useContext(StateContext)

    useEffect(() => {
        if (resourcesList.length > 0) {
            // On the first render the first active tab will always be the resourcesAggs[0]
            // and the resourcesList on page load will be it's resourcesList
            const tabName = resourcesAggs[0]?.typeCode
            if (tabName) {
                setActiveTab(tabName)
                setTabsData({
                    [tabName]: {
                        resources: resourcesList,
                        resourcesMore: resourcesMore
                    }
                })
            }
        }
        // Documents who incluedes -IFU in the original url will be displayed in line
        setIFUDocument(getIFUDocument(instructions, locale))
    }, [])

    useEffect(() => {
        if (activeTab !== '' && !tabsData[activeTab]) {
            // get the new resources based on the activeTab
            loadMoreResources()
        }
        if (errors?.resourcesAjaxError) {
            dispatch({ type: 'set_AJAX_errors', payload: { resourcesAjaxError: false } })
        }
    }, [activeTab])

    useEffect(() => {
        if (resourcesType !== '' && activeTab !== '') {
            // ResourcesType will be created after we clicked on a new tab the asynchronus ones
            // the first one is the only tab loaded at the beginning so it will
            // append new resources if the ResourcesType exist or it will create a new key with the resourcesType and the resources list
            // and resources more of the specific tab
            setTabsData((prevState) => ({
                ...prevState,
                [resourcesType]: {
                    resources: [
                        ...(prevState[resourcesType]
                            ? prevState[resourcesType].resources
                            : []),
                        ...resourcesList
                    ],
                    resourcesMore: resourcesMore
                }
            }))
            setIsLoading(false)
        }
    }, [resourcesType, resourcesList])

    const loadMoreResources = () => {
        const tabResourcesLength = tabsData[activeTab]
            ? tabsData[activeTab].resources.length
            : 0
        let endpoint = getLoadMoreURL(
            `${endpoints.moreResources}/`,
            tabResourcesLength,
            tabResourcesLength + 4
        )

        endpoint = `${endpoint}&contentType=${activeTab}`

        getRequest(endpoint)
            .then((result) => {
                if (result.data) {
                    dispatch({
                        type: 'pdp_load_more_resources',
                        payload: {
                            ...result.data,
                            contentType: activeTab
                        }
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'set_AJAX_errors',
                    payload: { resourcesAjaxError: true }
                })
                setIsLoading(false)
                dispatch({
                    type: 'show_error_message',
                    payload: `Error on loading more resources: ${error}`
                })
            })
        trackPDPData(`PDP Resources Link Click: ${showMore}`, 'Engagement Event')
    }

    const onClickLoadMoreButton = () => {
        if (!isLoading) {
            setIsLoading(true)
            loadMoreResources()
        }
    }

    return (
        <div ref={resourcesRef} className="sps2-pdp_section sps2-pdp_resources">
            <h3 tabIndex="-1" className="mds-font_header--3">
                {resources}
            </h3>
            {resourcesAggs?.length > 0 && tabsData && (
                <Tabs.TabsContainer defaultActive={0} alignment={'left'}>
                    {resourcesAggs?.map((tab, index) => (
                        <Tabs.TabItem
                            key={index}
                            title={`${tab.label} (${tab.count})`}
                            onClick={() => setActiveTab(tab.typeCode)}
                        >
                            <ProductBar.Container
                                loadMore={tabsData[activeTab]?.resourcesMore}
                            >
                                {activeTab !== '' &&
                                    tabsData[activeTab]?.resources?.map((resource, i) => {
                                        const nullFileValidation =
                                            resource?.contentType ||
                                            resource?.mimeType ||
                                            resource?.fileSize > 0
                                        const title = nullFileValidation
                                            ? resource?.title
                                            : techDataSheet
                                        return (
                                            <ProductBar.Item
                                                key={resource?.dmrId || title + i}
                                            >
                                                <ProductBar.Link
                                                    href={resource?.originalUrl}
                                                    hrefClass="mds-link mds-link_product"
                                                    onClick={() =>
                                                        trackPDPData(
                                                            `PDP Resources Link Click: ${title}`,
                                                            'Engagement Event'
                                                        )
                                                    }
                                                    target="_blank"
                                                    download
                                                >
                                                    {checkContentType(resource) && (
                                                        <ProductBar.Image
                                                            src={
                                                                isImageURL(resource?.url)
                                                                    ? resource?.url
                                                                    : NO_IMAGE
                                                            }
                                                            alt={removeEmphasis(
                                                                resource?.altText
                                                            )}
                                                        />
                                                    )}
                                                    <>
                                                        <Icons.icon_document fill="white" />
                                                        {fancyText(
                                                            title,
                                                            RESOURCE_TITLE_LENGTH
                                                        )}
                                                    </>
                                                </ProductBar.Link>
                                                {nullFileValidation && (
                                                    <ProductBar.DetailZone>
                                                        <p className="mds-font_paragraph">
                                                            {resource?.contentType}
                                                        </p>
                                                        {!checkContentType(resource) && (
                                                            <p className="mds-font_paragraph">
                                                                {documentNumber +
                                                                    ': ' +
                                                                    resource.dmrId}
                                                            </p>
                                                        )}
                                                        <p className="mds-font_body">
                                                            {`(${formatMIME(
                                                                resource?.mimeType
                                                            )}${
                                                                resource?.fileSize > 0
                                                                    ? `, ${formatBytes(
                                                                          resource?.fileSize
                                                                      )}`
                                                                    : ''
                                                            })`}
                                                        </p>
                                                    </ProductBar.DetailZone>
                                                )}
                                            </ProductBar.Item>
                                        )
                                    })}

                                {!errors?.resourcesAjaxError &&
                                    ((isDesktop &&
                                        tabsData[activeTab]?.resources?.length % 4 !==
                                            0) ||
                                        (!isDesktop &&
                                            tabsData[activeTab]?.resources?.length % 2 !==
                                                0)) && (
                                        <ProductBar.Item
                                            className="mds-prodBar_item sps2-pdp_blank-item"
                                            tabIndex="-1"
                                        ></ProductBar.Item>
                                    )}
                                {tabsData[activeTab]?.resourcesMore && (
                                    <ProductBar.LoadMore>
                                        <button
                                            onClick={onClickLoadMoreButton}
                                            className="mds-button mds-button_tertiary mds-button_tertiary--large "
                                        >
                                            {showMore}
                                        </button>
                                    </ProductBar.LoadMore>
                                )}
                                {errors?.resourcesAjaxError && (
                                    <div className="sps2-pdp_ajaxError--centered mds-margin_medium--top">
                                        <Alert.InContext variant="error">
                                            {ajaxError}
                                        </Alert.InContext>
                                    </div>
                                )}
                            </ProductBar.Container>
                        </Tabs.TabItem>
                    ))}
                </Tabs.TabsContainer>
            )}
            {IFUDocument !== '' && (
                <object
                    className="sps2-pdp_resources--IFU"
                    type="application/pdf"
                    data={IFUDocument.substring(
                        // no need to override the proxy

                        IFUDocument.indexOf('mws'),
                        IFUDocument.search(/\.pdf[&?]/g) + 4 // Appears to be inconsistent whether & or ? follows .pdf extension in document URL, use regex search for index
                    )}
                >
                    <div className="sps2-pdp_resources--IFU_Fallback">
                        <a target="_blank" href={IFUDocument} rel="noreferrer">
                            Instructions for Use
                        </a>
                    </div>
                </object>
            )}
        </div>
    )
}

export default Resources
