import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { getBrightcovePlayerInfo } from '../services/UtilityService'

/**
 * Brightcove video component
 */
const BrightcoveVideo = ({
    videoPlayerId,
    videoPlayerListId,
    videoPlayerType,
    modePlaylist,
    disablePlayButton
}) => {
    const brightcoveInfo = getBrightcovePlayerInfo(videoPlayerId)
    const containerRef = useRef(null)

    useEffect(() => {
        initBrightCoveVideo()
    }, [])

    const disableButton = () => {
        const playBtn = containerRef?.current?.querySelector('.vjs-big-play-button')
        playBtn?.setAttribute('disabled', 'disabled')
    }

    const initBrightCoveVideo = () => {
        const BrightcoveScript = document.getElementById('playerBrightcoveId')
        if (BrightcoveScript) {
            BrightcoveScript.remove()
        }
        if (brightcoveInfo) {
            const script = document.createElement('script')
            const body = document.getElementsByTagName('body')[0]
            script.src = `https://players.brightcove.net/${brightcoveInfo.dataAccountId}/${brightcoveInfo.dataPlayerId}_default/index.min.js`
            body.appendChild(script)
            script.setAttribute('id', 'playerBrightcoveId')
            script.onload = () => {
                if (disablePlayButton) {
                    disableButton()
                }
            }
        }
    }

    if (!brightcoveInfo) return null
    if (modePlaylist || videoPlayerType?.includes('Playlist')) {
        return (
            <div
                className={`vjs-playlist-player-container vjs-playlist-player-container--playlist ${
                    disablePlayButton ? 'vjs-playlist-player-container--disabled' : ''
                }`}
                ref={containerRef}
            >
                <video-js
                    id="videoPlayer"
                    data-playlist-id={videoPlayerListId}
                    data-account={brightcoveInfo.dataAccountId}
                    data-player={brightcoveInfo.dataPlayerId}
                    data-embed="default"
                    data-embed-options-playlist={true}
                    data-application-id
                    controls={!disablePlayButton}
                ></video-js>
            </div>
        )
    } else {
        return (
            <div
                className={`vjs-playlist-player-container ${
                    disablePlayButton ? 'vjs-playlist-player-container--disabled' : ''
                }`}
                ref={containerRef}
            >
                <video-js
                    id="videoPlayer"
                    data-video-id={videoPlayerListId}
                    data-account={brightcoveInfo.dataAccountId}
                    data-player={brightcoveInfo.dataPlayerId}
                    data-embed="default"
                    data-embed-options-playlist={false}
                    data-application-id
                    controls={!disablePlayButton}
                ></video-js>
            </div>
        )
    }
}

BrightcoveVideo.propTypes = {
    modePlaylist: PropTypes.bool,
    disablePlayButton: PropTypes.bool
}

BrightcoveVideo.defaultProps = {
    modePlaylist: true,
    disablePlayButton: false
}

export default BrightcoveVideo
